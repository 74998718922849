<!--
 * @Author: jinqingquan 2842427165@qq.com
 * @Date: 2023-09-18 20:07:30
 * @LastEditors: jinqingquan 2842427165@qq.com
 * @LastEditTime: 2024-04-19 11:56:20
 * @FilePath: \xjd_h5\src\view\upload\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="file-box" ref="boss">
    <div
      style="display:flex;font-size:15px; flex-direction: column; align-items: center; padding-right: 10px;">
      <div class="title" style="border:none;padding-bottom:5px">
        投标金额
      </div>
    </div>
    <div style="display:flex;align-items: center;  justify-content: space-between; padding:0px 16px 0px 0px;font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;">
      <Field type="number" v-model="quotation" placeholder="请输入"></Field>
      <div style="font-size:13px;width:150px;text-align: right;">{{budgetUnit}}</div>
    </div>
    <div style="width: 100vw;
    height: 12px;
    background: #F5F5F5;"></div>
    <div class="upload-box">
      <div class="title">
        投标文件<span>上传后不可修改，请定稿后上传</span>
      </div>
      <Uploader :max-size="20 * 1024 * 1024" accept=".xls,.xlsx,.doc,.docx,.pdf,.zip" @oversize="onOversize"
        :before-read="beforeRead"
        :after-read="afterRead">
        <!-- <Button icon="plus" type="primary">选择上传文件</Button> -->
        <div class="primary-button"><img src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/upload-icon.png" style="width:20px;height: 20px;margin-right: 8px;">点击上传文件</div>
        <div class="desc-tip">支持excel、word、pdf、zip格式，
最多上传5个文件，单个文件不能超过20mb</div>
      </Uploader>
    </div>
    <div class="file-list" v-if="fileList.length">
      <div class="file-list-box">
        <div class="file-list-item" v-for="(item, index) in fileList" :key="index">
          <div class="file-list-name">{{ item.originalName }}</div>
          <Icon
            name="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/close.png"
            @click="(e) => deleteFile(index, e)" size="16" />
        </div>
      </div>
    </div>
    <div class="line" v-if="examinationFlag == 1">
    </div>
    
    <div class="idcardImg" v-if="examinationFlag == 1 && biddingProcess == 2">
      <div class="idcardtitle">身份证照片</div>
      <div style="margin-top:23px">
        <Uploader :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :after-read="userIdcardtrueAdd">
          <img :src="userIdcardtrue ? userIdcardtrue : idcardImgtrue" alt="" class="idImgtrue">
          <span style="left:8vw;bottom:2vh">身份证人像面照片</span>
        </Uploader>
        <Uploader :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :after-read="userIdcardfalseAdd">
          <img :src="userIdcardfalse ? userIdcardfalse : idcardImgfalse" alt="" class="idImgfalse"
            style="margin-left:11px">
          <span style="left:11vw;bottom:2vh">身份证国徽面照片</span>
        </Uploader>
      </div>
    </div>
    <div v-if="examinationFlag == 1 && biddingProcess == 1">
      <div class="idcardImg">
        <div class="idcardtitle">资质文件</div>
        <div style="margin-top:23px;display: flex;
        justify-content: space-between;align-items: center; padding-bottom:10px " class="rowbox">
          <div class="fadingtitle" style="margin:0px; margin-left:20px;">上传营业执照图片</div>
          <Uploader :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :after-read="yingyeImgAdd">
            <img :src='yingyeImg' alt="" v-if="yingyeImg" style="width: 64px;height: 64px;margin-right: 20px;">
            <div class="rightimg" v-else><img
                src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/icon-%E7%9B%B8%E6%9C%BA-28%402x.png"
                alt=""></div>
          </Uploader>
        </div>
        <!-- <div style="margin-top:23px;display: flex;
        justify-content: space-between;align-items: center; padding-bottom:10px " class="rowbox">
          <div class="fadingtitle" style="margin:0px; margin-left:20px;">开户许可证</div>
          <Uploader :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :after-read="kaihuImgAdd">
            <img :src='kaihuImg' alt="" v-if="kaihuImg" style="width: 64px;height: 64px;margin-right: 20px;">
            <div class="rightimg" v-else><img
                src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/icon-%E7%9B%B8%E6%9C%BA-28%402x.png"
                alt=""></div>
          </Uploader>
        </div> -->
        <div style="margin-top:23px" class="rowbox">
          <div class="fadingtitle">法定代表人身份证</div>
          <Uploader :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :after-read="fadingIdcardtrueAdd">
            <img :src="fadingIdcardtrue ? fadingIdcardtrue : idcardImgtrue" alt="" class="idImgtrue">
            <span style="left:8vw;bottom:2vh">身份证人像面照片</span>
          </Uploader>
          <Uploader :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :after-read="fadingIdcardfalseAdd">
            <img :src="fadingIdcardfalse ? fadingIdcardfalse : idcardImgtrue" alt="" class="idImgfalse"
              style="margin-left:11px">
            <span style="left:11vw;bottom:2vh">身份证国徽面照片</span>
          </Uploader>
        </div>
        <div style="margin-top:23px" class="rowbox">
          <div class="fadingtitle">项目经理身份证</div>
          <Uploader :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :after-read="manageIdcardtrueAdd">
            <img :src="manageIdcardtrue ? manageIdcardtrue : idcardImgtrue" alt="" class="idImgtrue">
            <span style="left:8vw;bottom:2vh">身份证人像面照片</span>
          </Uploader>
          <Uploader :max-count="1" :max-size="20 * 1024 * 1024" @oversize="onOversize" :after-read="manageIdcardfalseAdd">
            <img :src="manageIdcardfalse ? manageIdcardfalse : idcardImgfalse" alt="" class="idImgfalse"
              style="margin-left:11px">
            <span style="left:11vw;bottom:2vh">身份证国徽面照片</span>
          </Uploader>
        </div>
      </div>
    </div>
    <div class="upload-box">
      <div class="title">
        其他材料<span>(选填)</span>：<span>招标需要的额外材料可在其他材料中上传</span>
      </div>
      <Uploader :max-size="20 * 1024 * 1024" accept=".xls,.xlsx,.doc,.docx,.pdf,.zip" @oversize="onOversize" 
      :before-read="beforeRead" :after-read="otherafterRead">
        <!-- <Button icon="plus" type="primary">选择上传文件</Button> -->
        <div class="primary-button"><img src="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/upload-icon.png" style="width:20px;height: 20px;margin-right: 8px;">点击上传文件</div>
        <div class="desc-tip">支持excel、word、pdf、zip格式，
最多上传5个文件，单个文件不能超过20mb</div>
      </Uploader>
    </div>
    <div class="file-list" v-if="otherfileList.length">
      <div class="file-list-box">
        <div class="file-list-item" v-for="(item, index) in otherfileList" :key="index">
          <div class="file-list-name">{{ item.originalName}}</div>
          <Icon
            name="https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/close.png"
            @click="(e) => otherdeleteFile(index, e)" size="16" />
        </div>
      </div>
    </div>
    <div class="btnbox">
      <div class="submit-btn" @click="submitFn" v-if="fileList.length">加密提交</div>
    </div>
    <Overlay :show="uploadLoading">
      <div style="position: absolute;top:50%;left:50%;transform: translate(-50%, -50%);">
        <div v-if="uploadFail" style="color:#fff;">上传失败</div>
        <Loading vertical style="color:#fff; font-size:20px;font-weight:500" v-else>上传中...</Loading>

      </div>
    </Overlay>
  </div>
</template>
<script>
import { Uploader, Button, Icon, Field, Loading, Overlay, Notify } from 'vant';

import axios from 'axios';
import { Toast } from 'vant'
export default {
  name: 'uploadH5',
  components: {
    Uploader, Button, Icon, Field, Loading, Overlay
  },
  data() {
    return {
      idcardImgtrue: 'https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E8%BA%AB%E6%AD%A3.png',
      idcardImgfalse: 'https://meiyun-mng.oss-cn-hangzhou.aliyuncs.com/upload/bigscreen/img/%E6%AD%A3%E5%BC%8F%E5%BA%93/%E6%B5%B7%E6%BD%AE/%E5%B0%8F%E5%BE%AE%E5%B7%A5%E7%A8%8B-%E5%B0%8F%E7%A8%8B%E5%BA%8F/%E8%BA%AB%E5%8F%8D.png',
      fileList: [],
      otherfileList: [],
      buttonValue: '',
      budgetUnit:'',
      uploadFail:false,
      uploadLoading:false,
      type: '',
      quotation: '',
      tabshow: '',
      examinationFlag: 1,
      enrollId: '1703649444511395842',
      projectId: '18677243904',
      userIdcardtrue: '',
      userIdcardfalse: '',
      fadingIdcardtrue: '',
      fadingIdcardfalse: '',
      manageIdcardtrue: '',
      manageIdcardfalse: '',
      yingyeImg: '',
      kaihuImg: '',
      biddingProcess: 2,
      intelligenceStatus: '',
      token: "bearer eyJ0eXBlIjoiSnNvbldlYlRva2VuIiwiYWxnIjoiSFMyNTYifQ.eyJ1c2VyX25hbWUiOiLpnbMiLCJwaG9uZSI6IjE3NjM0MzkzMzcxIiwicmVhbF9uYW1lIjoi6Z2z5riF5rOJIiwidGVuYW50X2lkIjoiMzMwNDgxMTA2MjI3IiwidXNlcl9pZCI6MTcwMjQ5Mjc0ODE1NTcxOTY4Miwicm9sZV9pZCI6IjQiLCJhY2NvdW50IjoiIiwidW5pZmllZF9jb2RlIjoiIiwiZXhwIjoxNjk1MzUyNzMxfQ.tQEjJRhe8TesyrHKExInnTmIl3aL6hLktRdERT33-CY"
    }
  },
  mounted() {
    let { query } = this.$route
    let { buttonValue = '', projectId = '', token, enrollId, biddingProcess, examinationFlag ,budgetUnit} = query
    this.buttonValue = decodeURIComponent(buttonValue)
    this.budgetUnit=budgetUnit
    console.log(query)
    this.token = token
    this.projectId = projectId
    this.enrollId = enrollId
    this.biddingProcess = biddingProcess
    this.examinationFlag = examinationFlag
    this.getUserinfo()
  },
  methods: {
    getUserinfo() {
      axios.defaults.headers = {
        'Blade-Auth': this.token
      };
      axios
        .get('/meiyun-smallengineering/user/getUserInfo')
        .then((res) => {
          console.log(res, 111111)
          this.userIdcardtrue = res.data.data.idCardPhoto.split(',')[0]
          this.userIdcardfalse = res.data.data.idCardPhoto.split(',')[1]
          this.fadingIdcardtrue = res.data.data.legalRepresentative.split(',')[0]
          this.fadingIdcardfalse = res.data.data.legalRepresentative.split(',')[1]
          this.yingyeImg = res.data.data.businessLicense;
          // this.kaihuImg = res.data.data.permit;
          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        });
    },
    deleteFile(index, e) {
      
      let { fileList } = this
      fileList.splice(index, 1)
      this.fileList = [...fileList]
      // wx.miniProgram.postMessage({ data: JSON.stringify(this.fileList) });
    },
    otherdeleteFile(index, e) {
      let { otherfileList } = this
      otherfileList.splice(index, 1)
      this.otherfileList = [...otherfileList]
      this.$nextTick(() => {
        let scrollEl = this.$refs.boss;
        scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
      });
    },
    onOversize(file) {
      Toast('文件大小不能超过20mb')
    },
    beforeRead(file){
      let arr = file.name.split('.')
      let type = arr[arr.length-1]
      if(['xls','xlsx', 'doc', 'docx', 'pdf', 'zip'].includes(type)){
        return true
      }else{
        Notify({
          message: '请上传excel、word、pdf、zip格式文件',
          color: '#fff',
          background: 'rgba(0,0,0,0.6)',
          className:'more-file-notice'
        })
        return false
      }
    },
    afterRead(file) {
      if(this.fileList.length>4){
        Notify({
          message: '最多上传5个文件',
          color: '#fff',
          background: 'rgba(0,0,0,0.6)',
          className:'more-file-notice'
        })
        return
      }
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true
      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          // this.deleteFile(0)
          this.uploadLoading = false
          this.fileList.push(res.data.data)
          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    userIdcardtrueAdd(file) {
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true
      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.userIdcardtrue = res.data.data.link
          this.uploadLoading = false
          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    userIdcardfalseAdd(file) {
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true

      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.userIdcardfalse = res.data.data.link
          this.uploadLoading = false

          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    fadingIdcardtrueAdd(file) {
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true
      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.fadingIdcardtrue = res.data.data.link
          this.uploadLoading = false
          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    fadingIdcardfalseAdd(file) {
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true

      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.fadingIdcardfalse = res.data.data.link
          this.uploadLoading = false

          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    manageIdcardtrueAdd(file) {
      let formdata = new FormData()
      formdata.append('file', file.file)
      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.manageIdcardtrue = res.data.data.link
          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    manageIdcardfalseAdd(file) {
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true

      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.manageIdcardfalse = res.data.data.link
          this.uploadLoading = false

          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    yingyeImgAdd(file) {
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true

      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.yingyeImg = res.data.data.link
          this.uploadLoading = false
          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    kaihuImgAdd(file) {
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true

      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.kaihuImg = res.data.data.link
          this.uploadLoading = false

          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },

    otherafterRead(file) {
      if(this.otherfileList.length>4){
        Notify({
          message: '最多上传5个文件',
          color: '#fff',
          background: 'rgba(0,0,0,0.6)',
          className:'more-file-notice'
        })
        return
      }
      let formdata = new FormData()
      formdata.append('file', file.file)
      this.uploadLoading = true

      axios.defaults.headers = {
        'Blade-Auth': this.token,
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
      };
      axios
        .post('/meiyun-smallengineering/file/put-file', formdata)
        .then((res) => {
          this.uploadLoading = false
          // if(this.otherdeleteFile.length==3){
          //   this.otherdeleteFile(-1)
          // }
          this.otherfileList.push(res.data.data)
          this.$nextTick(() => {
            let scrollEl = this.$refs.boss;
            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
          });
          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
        }).catch(()=>{
          this.uploadFail = true;
          setTimeout(()=>{
            this.uploadLoading = false
            this.uploadFail = false
          }, 1500)
        });
    },
    transformFileData(list){
      return JSON.stringify(list.map(i=>{
        return  {
          url: i.link,
          name: i.originalName
        }
      }))
    },
    submitFn() {
      let { fileList, enrollId, otherfileList, userIdcardtrue, userIdcardfalse, fadingIdcardtrue, fadingIdcardfalse, manageIdcardtrue, manageIdcardfalse, yingyeImg, kaihuImg, biddingProcess, examinationFlag, quotation } = this
      if (!quotation) {
        return Toast("请输入投标金额")
      }
      if (!(userIdcardtrue) && examinationFlag == 1 && biddingProcess == 2) {
        return Toast("请上传身份证正面照片")
      }
      if (!(userIdcardfalse) && examinationFlag == 1 && biddingProcess == 2) {
        return Toast("请上传身份证正面照片")
      }
      if (!(yingyeImg) && examinationFlag == 1 && biddingProcess == 1) {
        return Toast("请上传营业执照照片")
      }
      // if (!(kaihuImg) && examinationFlag == 1 && biddingProcess == 1) {
      //   return Toast("请上传开户许可证照片")
      // }
      if (!(fadingIdcardtrue) && examinationFlag == 1 && biddingProcess == 1) {
        return Toast("请上传法定人身份证反面照片")
      }
      if (!(fadingIdcardfalse) && examinationFlag == 1 && biddingProcess == 1) {
        return Toast("请上传法定人身份证反面照片")
      }
      if (!(manageIdcardtrue) && examinationFlag == 1 && biddingProcess == 1) {
        return Toast("请上传项目经理身份证正面照片")
      }
      if (!(manageIdcardfalse) && examinationFlag == 1 && biddingProcess == 1) {
        return Toast("请上传项目经理身份证反面照片")
      }
      let legalIdCard = '';
      let pmIdCard = ''
      if (biddingProcess == 2 && userIdcardtrue && examinationFlag) {
        legalIdCard = userIdcardtrue + ',' + userIdcardfalse
      }
      if (biddingProcess == 1 && fadingIdcardtrue && examinationFlag) {
        legalIdCard = fadingIdcardtrue + ',' + fadingIdcardfalse
      }
      if (biddingProcess == 1 && manageIdcardtrue && examinationFlag) {
        pmIdCard = manageIdcardtrue + ',' + manageIdcardfalse
      }
      if (biddingProcess == 2) {
        yingyeImg = '';
        kaihuImg = ''
      }
      axios.defaults.headers = {
        'Blade-Auth': this.token
      };
      axios
        .post('/meiyun-smallengineering/project/Supplier/uploadFile',
          { 
            id: enrollId, 
            bidDocument: this.transformFileData(fileList),
            bidDocumentStatus: 1, 
            legalIdCard, 
            quotation, 
            pmIdCard, 
            businessLicense: yingyeImg, 
            // permit: kaihuImg, 
            otherFile: this.transformFileData(otherfileList) })
        .then((res) => {
          console.log(res)
          // wx.miniProgram.postMessage({ data: {data:JSON.stringify(this.fileList)} });
          if (res.data.success) {
            Toast.success('提交成功');
            setTimeout(() => {
              wx.miniProgram.redirectTo({
                url: '/pages/uploadBiddingDoc/modal',
              })
            }, 1500);
          }else{
            Toast.fail(res.data.msg);
            setTimeout(() => {
              wx.miniProgram.redirectTo({
                url: '/pages/home/index',
              })
            }, 1500);
          }
        }).catch(err => {
          console.log(err)
          // wx.miniProgram.redirectTo({
          //   url: '/pages/tempjump/index?type='+this.type+'&returnData='+JSON.stringify({data:'sdddd'}),
          // })
        });
    },
  }
}
</script>
<style scoped lang="scss">
.btnbox {
  background: #fff;
  position: fixed;
  bottom: 0px;
  width: 100%;

}

.line {
  width: 100%;
  height: 12px;
  background: #F5F5F5;
}

.rightimg {

  width: 64px;
  height: 64px;
  border-radius: 4px;
  border: 1px solid #DDDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.rightimg img {

  width: 28px !important;
  height: 28px !important;
  background: #FFFFFF;
}

.rowbox {
  border-bottom: 1px solid #EEEEEE;

}

.fadingtitle {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 20px;
}

.idcardImg {
  width: 100%;
  position: relative;

  img {
    width: 166px;
    height: 109px;
  }

  span {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    position: absolute;
  }

  .idcardtitle {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    text-align: left;
    padding-left: 16px;
    margin-top: 16px;
    padding-bottom: 17px;
    border-bottom: 1px solid #EEEEEE;
  }
}

.title {
  width: 100vw;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  border-bottom: 2px solid #EEEEEE;
  padding: 18px;
  box-sizing: border-box;

  span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-left: 4px;
  }
}

.file-box {
  text-align: center;
  position: relative;
  width: 100%;
  height: 100vh;
  padding-bottom: 12vh;
  overflow: scroll;
  box-sizing: border-box;
}

.primary-button {
  color: #1677FF;
  border-radius: 8px;
  // width: 100vw;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1677FF;
  text-align: center;
  // margin-top: 23px;
  display: flex;
  justify-content: center;
  padding: 12px;
  margin: 16px;
  border: 1px solid;
  box-sizing: border-box;
  width: auto;
}

.submit-btn {
  color: #fff;
  background-color: #1677FF;
  border-radius: 8px;
  width: 80vw;
  height: 44px;
  font-size: 18px;
  line-height: 44px;
  margin: 20px auto;
  text-align: center;
}

.desc-tip {
  // width: 100vw;
  font-size: 12px;
  color: #999;
  margin: 4px 16px 10px;
  text-align: center;
  // margin-bottom: 10px;


}

.file-list {
  padding: 12px 12px;

  &-box {
    border: 1px solid #eee;
    border-radius: 4px;
    // max-height: 30vh;
    // overflow-y: auto;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #1677FF;
    padding: 20px 12px;

    &+& {
      border-top: 1px solid #eee;
    }
  }

  &-name {
    max-width: 300px;
    text-align: left;
    word-wrap: break-word;

  }
}</style>
<style lang="scss">
.more-file-notice{
  position: fixed;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width: 120px;
  font-size: 14px;
  background: rgba(0,0,0,0.6);
  height: 120px;
  border-radius: 8px;
}
</style>